import "./Styles/Work.css";
import React from "react";
import phone1 from "../Componenets/Assets/Images/phone1.png";
import phone2 from "../Componenets/Assets/Images/phone2.png";
import phone3 from "../Componenets/Assets/Images/phone3.png";
import iphone from "../Componenets/Assets/Images/iphone.png";
import andriod from "../Componenets/Assets/Images/android.png";

const Work = () => {
  return (
    <div id="work">
      <div className="work">
        <div className="title">
          <h2>How we work</h2>
        </div>
        <div className="work-row">
          <div className="card">
            <div>
              <h3>Download the App</h3>
              <p>
                Get started by downloading our app from the Google Play Store or
                Apple App Store.
              </p>
            </div>
            <div className="img-div">
              <img
                src={phone1}
                alt="iPhone 12 Pro Max displaying a loading screen for the app"
              />
            </div>
          </div>
          <div className="card">
            <div>
              <h3>Submit Details & Apply</h3>
              <p>
                Simply fill in your details and submit your loan
                applicationwithin minutes.
              </p>
            </div>
            <div className="img-div">
              <img
                src={phone2}
                alt="iPhone 12 Pro Max displaying the details and how to apply on the screen for the app"
              />
            </div>
          </div>
          <div className="card">
            <div>
              <h3>Get your loans in minutes</h3>
              <p>
                Recieve your approved loan directly to your account and
                effortlessly.
              </p>
            </div>
            <div className="img-div">
              <img
                src={phone3}
                alt="iPhone 12 Pro Max displaying a screen on a recieved loan for the app"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="store-btn store-btn2">
        <img
          className={"btn-img"}
          src={iphone}
          alt="Download on the Apple Store"
        />
        <img
          className={"btn-img"}
          src={andriod}
          alt="Download on the Google Play"
        />
      </div>
      <footer className="footer">
        <small>
          Copyright @2024 Credibility Financial Services Limited &reg; . All
          Right Reserved
        </small>
      </footer>
    </div>
  );
};

export default Work;
