import { useEffect, useState } from "react";

export function DisappearingComponent({ children, timeout = 3000 }) {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(false);
        }, timeout);

        return () => clearTimeout(timer);
    }, [timeout]);

    if (!isVisible) return null;

    return <>{children}</>;
}