import { Toaster } from 'react-hot-toast';
import './App.css';
import Download from './Componenets/Download';
import Work from './Componenets/Work';
import { DeleteMyAccount } from './page/DeleteMyAccount';
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Toaster />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<>
            <Download />
            <Work />
          </>} />
          <Route path="/delete-my-account" element={<DeleteMyAccount />} />
        </Routes>
      </BrowserRouter>
    </div>);
}

export default App;
