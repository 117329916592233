import "./Styles/Download.css";
import iphone from "../Componenets/Assets/Images/iphone.png";
import android from "../Componenets/Assets/Images/android.png";
import hand from "../Componenets/Assets/Images/hand.png";
import logo from './Assets/Images/Logo.png'
import React from "react";

const Download = () => {
  return (
    <div>
      <div className="row">
        <div className="left-div">
          <div className="img_div">
            {" "}
            <img
              src={logo}
              alt="CashGo logo - Quick and Easy Personal Loans"
              className="logo"
            />
            <button className="btn btn2">Download App</button>
          </div>
          <div>
            <h1>
              Get fast and easy loans with Credilty financial service Limited
            </h1>
            <p>
              Get the funds you need quickly and effortlessly. Download the
              cashGo app today and experience seamless loan approvals in minutes
            </p>
          </div>
          <div className="store-btn">
            <img
              className={"btn-img"}
              src={iphone}
              alt="Download on the Apple Store"
            />
            <img
              className={"btn-img"}
              src={android}
              alt="Download on the Google Play"
            />
          </div>
        </div>
        <div className="right-div">
          <button className="btn">Download App</button>
          <div className="">
            <img src={hand} alt="Free hand holding an iphone 12 pro max" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Download;
