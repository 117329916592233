import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../Componenets/Assets/Images/Logo.png';
import spinner from '../Componenets/Assets/Images/spinner.svg';
import see from '../Componenets/Assets/Images/eye.svg';
import nosee from '../Componenets/Assets/Images/eye-alt.svg';
import { useDebounce, validateEmail } from '../utilities';
import { DisappearingComponent } from '../Componenets/DisappearingComponent';
import toast from 'react-hot-toast';

export const DeleteMyAccount = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();


    const emailDebouced = useDebounce(email);
    const passwordDebouced = useDebounce(password);

    const [errorObject, setErrorObject] = useState({
        email: { status: 0, error: "" },
        password: { status: 0, error: "" },
        login: { status: 0, error: "" }
    });

    useEffect(() => {
        if (!emailDebouced) {
            setErrorObject((prev) => ({ ...prev, email: { status: 0, error: "" } }));
            return;
        }

        if (!validateEmail(emailDebouced)) {
            setErrorObject((prev) => ({
                ...prev,
                email: { status: 1, error: "Invalid email address!" },
            }));
            return;
        }

        setErrorObject((prev) => ({ ...prev, email: { status: 2, error: "" } }));
    }, [emailDebouced]);

    useEffect(() => {
        if (!passwordDebouced) {
            setErrorObject((prev) => ({
                ...prev,
                password: { status: 0, error: "" },
            }));
            return;
        }

        if (String(passwordDebouced).length <= 5) {
            setErrorObject((prev) => ({
                ...prev,
                password: { status: 1, error: "Invalid password format!" },
            }));
            return;
        }

        setErrorObject((prev) => ({ ...prev, password: { status: 2, error: "" } }));
    }, [passwordDebouced]);

    const canProceed = useMemo(() => {
        return (
            errorObject.email.status === 2 &&
            errorObject.password.status === 2 &&
            !isLoading
        );
    }, [errorObject, isLoading]);

    const performDeleteMyAccount = async () => {
        setIsLoading(true);
        try {
            const response = await fetch("http://localhost:3000/api/user/delete-my-account/web", {
                method: "POST",
                headers:  {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: emailDebouced,
                    password: passwordDebouced,
                }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'An unexpected error occurred!');
            }

            setEmail("")
            setPassword("");

            navigate("/");
        } catch (error) {
            setErrorObject((prev) => ({ ...prev, login: { status: 1, error: error.message ?? "An error occurred" } }));
            console.error(error)
            throw new Error(error.message ?? "An error occurred");
        } finally {
            setIsLoading(false);
        }
    };

    const handleLogin = (e) => {
        e.preventDefault();
        if (!canProceed) return;  // Prevent form submission if validation fails

        const promise = performDeleteMyAccount();
        toast.promise(promise, {
            loading: "Deleting account...",
            success: "Account deleted successfully!",
            error: "Failed to delete account",
        });
    };

    return (
        <div className='delete-container'>
            <main>
                <Link to={"/"}>
                    <img
                        alt='Cashgo Logo'
                        src={logo}
                    />
                </Link>

                <p>
                    <span>Are you sure you want to delete your account?</span> This action is permanent and cannot be undone. All your loan history, account information, and associated data will be deleted. Please login your account credentials to proceed with deleting your account.
                </p>

                <form action="#" onSubmit={handleLogin}>
                    <div class="inp-container">
                        <label for="email">Email Address: <span className="error"> {`${errorObject.email.error}`}</span></label>
                        <input 
                            type="email" 
                            className={errorObject.email.status === 1 ? "error" : ""} 
                            id="email" 
                            name="email" 
                            required 
                            value={email}
                            onChange={(e)=>setEmail(e.target.value)}
                        />
                    </div>
                    <div class="inp-container">
                        <label for="password">Password: <span className="error"> {`${errorObject.password.error}`}</span></label>
                        <div class="passwordInput">
                            <input
                                type={passwordVisible ? "text" : "password"}
                                className={errorObject.password.status === 1 ? "error" : ""}
                                id="password"
                                name="password"
                                required
                                value={password}
                                onChange={(e)=>setPassword(e.target.value)}
                            />

                            {passwordDebouced.length > 0 && <div className="passwordEye" onClick={()=>setPasswordVisible(!passwordVisible)}>
                                <img
                                    src={see}
                                    alt="hide"
                                    className={!passwordVisible ? "active" : "inactive"}
                                />
                                <img
                                    className={!passwordVisible ? "inactive" : "active"}
                                    src={nosee}
                                    alt="reveal"
                                />
                            </div>}
                        </div>
                    </div>

                    <p>
                        {errorObject.login.status === 1 && <DisappearingComponent timeout={25000}>
                            <span className="errorMsg"> {errorObject.login.error}</span>
                        </DisappearingComponent>}
                    </p>

                    <div class="inp-container">
                        <button
                            className={`${canProceed ? "clickable" : "disabled"}`}
                            type='submit'
                        >
                            {isLoading ? (
                                <img alt="spinner" src={spinner} height={25} />
                            ) : (
                                "Delete My Account"
                            )}
                        </button>
                    </div>
                </form>
            </main>
        </div>
    )
}
